<template>
  <cw-page
    icon="check"
    class="cw-business-loan-complete"
  >
    <template #hero-title>
      <translate>
        Welcome to Saldo
      </translate>
    </template>

    <v-row>
      <v-col
        class="
          cw-border-container
          cw-border-container--primary
        "
        cols="12"
        md="8"
      >
        <p class="font-weight-bold">
          <translate>Thank you for choosing our service!</translate>
        </p>
        <p>
          <translate v-if="isSmePosInstalmentLoan">
            Your company has been granted a Corporate Loan, the corresponding amount of which has
            been transferred to the account of the party who sold the product or service.
          </translate>
          <translate v-else>
            Your company has been granted a Business Loan, which has been processed and will be
            paid to the account specified in the loan application.
          </translate>
        </p>
        <p v-if="isSmePosInstalmentLoan">
          <translate>
            The first invoice for the loan will be sent to the email address provided in the
            application. If you would like future invoices in the mail, please contact our
            customer service.
          </translate>
        </p>
        <p class="font-weight-bold">
          <translate>
            You can manage your loans easily at MySaldo.
            In MySaldo you can see your available additional services such as the
            Saldo Safe loan insurance that insures your payment ability in case of
            circumstances such as unemployment.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwBusinessLoanComplete',

  computed: {
    ...mapGetters({
      product: 'application/getProduct',
    }),

    isSmePosInstalmentLoan() {
      return [
        'Saldo.Finland.SmePosInstalmentLoan',
      ].includes(this.product.productName);
    },
  },

  created() {
    this.setPurchase(false);
  },

  methods: {
    ...mapActions({
      setPurchase: 'application/setPurchase',
    }),
  },
};
</script>
